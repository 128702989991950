import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Polyline,
  TrafficLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { searchWeather } from "../apis/GETWeather";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 625,
  height: 775,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  alignItems: "center",
  justifyContent: "center",
};

const containerStyle = {
  width: 575,
  height: 575,
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
};

export default function RouteModal(props) {
  const { setOpen, open, travelInfo, directionsRes } = props;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBs6JPnud56PGiauo305qCVrY5B3cqWd4M",
  });

  const center = travelInfo.startPoints;
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{ background: "black", color: "white", width: "215px" }}
      >
        Route Information
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{display:"flex"}}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{
               ml:"auto",
                textAlign: "center",
                textDecoration: "underline",
                textShadow: 10,
              }}
           
            >
              Route Information
            </Typography>
            <IconButton  onClick={handleClose} sx={{alignItems:"right",ml:"auto"}}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <strong>Origin Address:</strong> {travelInfo.startAddress}
            <br />
            <strong> Destination Address:</strong> {travelInfo.endAddress}
            <br />
            <strong>Travel Miles: </strong> {travelInfo.distance}
            <br />
            <strong>Travel Time: </strong>
            {travelInfo.travelTime}
          </Typography>
          <Box sx={{ height: 30 }} />
          {isLoaded && open ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <TrafficLayer autoUpdate />

              <DirectionsRenderer
                directions={directionsRes}
                options={{
                  polylineOptions: {
                    strokeOpacity: 0.25,
                    zIndex: -1,
                    strokeColor: "blue",
                  },
                }}
              ></DirectionsRenderer>
            </GoogleMap>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
