import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EntryCard from "./EntryCard.js";
import { getFXFCenter, getAllCenters } from "../apis/GETCenter.js";
import TravelMap from "../map/TransitMap.js";
import BidTable from "../bids/BidTable.js";
import RouteModal from "./routeModal.js";
import WeatherModal from "../weather/weatherModal.js";

const defaultValues = {
  street: "Enter FXF Code",
  street2: "n/a",
  city: "n/a",
  state: "n/a",
  zip: "n/a",
  hours: "n/a",
};

function MainPage() {
  const [fieldEntryPU, setFieldEntryPU] = useState("");
  const [pickupAddress, setPickupAddress] = useState(defaultValues);
  const [fieldEntryDO, setFieldEntryDO] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState(defaultValues);
  const [showSubmit, setShowSubmit] = useState(false);
  const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0.41); 
  const [fuelSurchargeTotal, setFuelSurchargeTotal] = useState(0);
  const [travelInfo, setTravelInfo] = useState({});
  const [bidGrid, setBidGrid] = useState({ origin: "", destination: "" });
  const [fxfCenters, setFxfCenters] = useState([]);
  const [openRoute, setOpenRoute] = useState(false);
  const [openWeather, setOpenWeather] = useState(false)
  const [directionsRes, setDirectionsRes] = useState();

  useEffect(() => {
    async function fetchAllCenters() {
      await getAllCenters().then((res) => {
        let allCenters = res;
        allCenters.splice(0, 0, "");
        setFxfCenters(allCenters);
      });
    }

    fetchAllCenters();
  }, []);


  useEffect(() => {
    if (fieldEntryDO.length === 3 && fieldEntryPU.length === 3) {
      setShowSubmit(true);
    }
  }, [fieldEntryDO, fieldEntryPU]);

  //extracts numbers form mileage return
  useEffect(() => {
    if (travelInfo.distance) {
      let fuelCost =
        travelInfo.distance.replace(/[^0-9.]/g, "") * fuelSurchargeAmount;
      console.log("travelInfo from useEffect on MainPage", travelInfo);
      setFuelSurchargeTotal(fuelCost.toFixed(2));
    } else return;
  }, [travelInfo, fuelSurchargeAmount]);

  return (
    <>
      <Box mt=".5rem"></Box>
      <Box>
        <Typography variant="h1">FXF Rate Calculator</Typography>
      </Box>
      <Box my="2rem"></Box>
      <Grid container spacing={4} justifyContent="center">
        <Grid
          item
          style={{
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              boxShadow: 10,
              borderRadius: "16px",
            }}
          >
            <EntryCard
              title="Origin"
              fieldEntry={fieldEntryPU}
              setField={setFieldEntryPU}
              address={pickupAddress}
              centers={fxfCenters}
            />
          </Box>
        </Grid>
        <Grid
          item
          style={{
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              boxShadow: 10,
              borderRadius: "16px",
            }}
          >
            <EntryCard
              title="Destination"
              fieldEntry={fieldEntryDO}
              setField={setFieldEntryDO}
              address={deliveryAddress}
              centers={fxfCenters}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box
          sx={{
            my: "1rem",
            mx: "auto",
            py: ".75rem",
            maxWidth: "12rem",
            backgroundColor: "#dadada",
            boxShadow: 10,
            borderRadius: "16px",
          }}
        >
          <Typography color="black" fontWeight="bold">
            Current FSC Amount:
          </Typography>
          {/* <br /> */}
          <TextField
            sx={{ maxWidth: "6rem", backgroundColor: "white" }}
            variant="outlined"
            size="small"
            type="number"
            value={fuelSurchargeAmount}
            onChange={(e) => {
              setFuelSurchargeAmount(e.target.value);
            }}
          ></TextField>
        </Box>
        <ButtonGroup size="large">
          {showSubmit ? (
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black", mb: "1rem" }}
              onClick={async (e) => {
                await getFXFCenter(fieldEntryPU).then((center) => {
                  // console.log(center.data);
                  setPickupAddress(center.data);
                });
                await getFXFCenter(fieldEntryDO).then((center) => {
                  // console.log(center.data);
                  setDeliveryAddress(center.data);
                });
                setBidGrid({ origin: fieldEntryPU, destination: fieldEntryDO });
                setFieldEntryPU("");
                setFieldEntryDO("");
                setShowSubmit(false);
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              disabled
              variant="contained"
              sx={{ backgroundColor: "light gray", color: "dark gray" }}
            >
              Submit
            </Button>
          )}
          <Button
            variant="contained"
            style={{ backgroundColor: "black", color: "white" }}
            onClick={(e) => {
              // console.log(e);
              window.location.reload(true);
              // resetValues();
            }}
          >
            Reset
          </Button>
        </ButtonGroup>

        {travelInfo.distance && travelInfo.distance !== "" ? (
          <>
            <Box sx={{ my: "1rem" }}>
              <Typography sx={{ pb: ".25rem" }}>
                Total Miles : <strong>{travelInfo.distance}</strong>
              </Typography>
              <Typography>
                Current FSC ${fuelSurchargeAmount}/mi : ${fuelSurchargeTotal}
              </Typography>
            </Box>
            <RouteModal
              open={openRoute}
              setOpen={setOpenRoute}
              travelInfo={travelInfo}
              directionsRes={directionsRes}
            />
            <WeatherModal
               open={openWeather}
              setOpen={setOpenWeather}
              travelInfo={travelInfo}
              pickupAddress={pickupAddress}
              deliveryAddress={deliveryAddress}
            />
          </>
        ) : null}
      </Box>

      <TravelMap
        PUaddress={pickupAddress}
        DOaddress={deliveryAddress}
        setTravelInfo={setTravelInfo}
        // travelInfo={travelInfo}
        setDirectionsRes={setDirectionsRes}
      />
      <br />
      {bidGrid.origin !== "" ? <BidTable bids={bidGrid} /> : null}
    </>
  );
}

export default MainPage;
