import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { searchBids } from "../apis/GETBids";
import { Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

export default function BidTable(props) {
  const [rows, setRows] = useState([]);
  const { bids } = props;
  function createData(bidID, date, origin, destination, rate, status) {
    return { bidID, date, origin, destination, rate, status };
  }

  function setReadDate(dateTime) {
    let date = new Date(dateTime);
    let readDate = date.toDateString();
    let time = date.toLocaleTimeString("en-US",{timeZone:"America/Chicago"});
    return readDate + " | " + time;
  }

  async function createTable() {
    if (bids.origin !== "") {
      await searchBids(bids.origin, bids.destination).then((res) => {
        // console.log("the res from createTable", res.data);
        res.data.map((bid) => {
          if (bid) {
            let accepted;
            if (bid.accepted === true) {
              accepted = "true";
            } else {
              accepted = "false";
            }
            return setRows((oldRows) => [
              ...oldRows,
              createData(
                bid.bidID,
                setReadDate(bid.dateTime),
                bid.origin,
                bid.destination,
                bid.rate,
                accepted
              ),
            ]);
          } else return null;
        });
      });
    }
  }

  useEffect(() => {
    setRows([]);
    createTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids]);

  return rows.length > 0 ? (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "75%", mx: "auto", boxShadow: 10, borderRadius: "16px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>BID ID</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="right">Origin</TableCell>
            <TableCell align="right">Destination</TableCell>
            <TableCell align="right">Bid Rate</TableCell>
            <TableCell align="center">Accepted</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.bidID}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {row.bidID}
              </TableCell>
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="right">{row.origin}</TableCell>
              <TableCell align="right">{row.destination}</TableCell>
              <TableCell align="right">${row.rate}</TableCell>
              {row.status === "true" ? (
                <TableCell align="center">
                  <DoneIcon color="success" />
                </TableCell>
              ) : (
                <TableCell align="center">
                  <DoNotDisturbAltIcon sx={{ color: "red" }} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Typography variant="h3" sx={{ color: "warning.light" }}>
      NO BIDS FOR THIS LANE
    </Typography>
  );
}
