import axios from "axios";

export async function searchBids(origin, destination) {
  let bidArray = [];
  const axiosGETConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    mode: "cors",
    url: "https://yt.sidestories.space/bids/" + origin + "/" + destination,
  };

  await axios(axiosGETConfig).then((bids) => {

    bidArray = bids;
  });
  return bidArray;
}
