import './App.css';
import { Box } from '@mui/material';
import MainPage from './calc/MainPage.js';


function App() {
  return (
    <div className="App" sx={{mx:"auto", textAlign:"center"}}>
    <MainPage />
    <Box  sx={{display:"flex", justifyContent:"center", my:"2rem"}}>
 
    </Box>
   
    </div>
  );
}

export default App;
