import axios from "axios";

export async function simpleRoute(steps, start, end) {
  // console.log(steps, start, end);
  let routeArray = [];
  // let sortedArray = [];
  await steps.map((step, i) => {
    // console.log(i);
    let weatherObj;
    const axiosGETConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "cors",
      url: "https://api.weather.gov/points/" + step.lat() + "," + step.lng(),
    };

    axios(axiosGETConfig)
      .then((res) => {
        if (
          !routeArray.some(
            (route) => route.gridId === res.data.properties.gridId
          )
        ) {
          // console.log("Properties of weather response", res.data.properties);
          let props = res.data.properties;
          let locale = res.data.properties.relativeLocation;
          weatherObj = {
            gridId: props.gridId,
            forecast: props.forecast,
            forecastObj: {},
            city: locale.properties.city,
            state: locale.properties.state,
            timeZone: props.timeZone,
            coordinates: {
              lat: locale.geometry.coordinates[1],
              lng: locale.geometry.coordinates[0],
            },
          };
          routeArray.push(weatherObj);
          return weatherObj;
        } else {
          return;
        }
      })
      .then((res) => {
        // console.log("the res in the second then", res);
        setTimeout(() => {
          if (res !== undefined) {
            const axiosGETRouteConfig = {
              method: "GET",
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
              mode: "cors",
              url: res.forecast,
            };
            try {
              axios(axiosGETRouteConfig).then((forecast) => {
                let forecastArray = forecast.data.properties.periods;
                // console.log("ForecastArray from getRouteConfig", forecastArray);
                weatherObj.forecastObj = {
                  periodOne: forecastArray[0],
                  periodTwo: forecastArray[1],
                  periodThree: forecastArray[2],
                };

                console.log("the forecast Object");
              });
            } catch (err) {
              setTimeout(() => {
                axios(axiosGETRouteConfig).then((forecast) => {
                  let forecastArray = forecast.data.properties.periods;
                  // console.log("ForecastArray from getRouteConfig", forecastArray);
                  weatherObj.forecastObj = {
                    periodOne: forecastArray[0],
                    periodTwo: forecastArray[1],
                    periodThree: forecastArray[2],
                  };

                  console.log("the forecast Object");
                });
              }, 1500);
            }
          }
        }, 300);
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  });
  // if (start > end) {
  //   console.log("Start > End");

  //   sortedArray = routeArray.sort((a, b) => {
  //     return a.coordinates.lng + b.coordinates.lng;
  //   });
  // } else if (end > start) {
  //   console.log("End > Start");
  //   sortedArray = routeArray.sort((a, b) => {
  //     return a.coordinates.lng - b.coordinates.lng;
  //   });
  // }
  return routeArray;
}

// export async function getForecast(routeArray) {
//   console.log("the route array from get forecast", routeArray);
//   let routeWeather = [];
//   let returnObject = { route: routeArray, forecast: routeWeather };

//   routeArray.map(async (route) => {
//     console.log("the route in getForecast", route);

//     const axiosGETRouteConfig = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
//       },
//       mode: "cors",
//       url: route.forecast,
//     };
//     await axios(axiosGETRouteConfig).then((forecast) => {
//       let forecastArray = forecast.data.properties.periods;
//       console.log("ForecastArray from getRouteConfig", forecastArray);
//       let forecastObject = {
//         periodOne: forecastArray[0],
//         periodTwo: forecastArray[1],
//         periodThree: forecastArray[2],
//       };
//       routeWeather.push(forecastObject);
//       console.log("the forecast Object", forecastObject);
//     });
//   });
//   console.log("The Return Object from getForecast", returnObject);
//   return returnObject;
// }
