import axios from "axios";

export async function getFXFCenter(center) {
  let FXFCenter = {};
  const axiosGETConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    mode: "cors",
    url: "https://yt.sidestories.space/centers/" + center,
  };
  await axios(axiosGETConfig).then((center) => {
    FXFCenter = center;
  });
  return FXFCenter;
}

export async function updateFXFCenter(center) {}

export async function getAllCenters() {
  let FXFCenters = [];

  const axiosGETConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    mode: "cors",
    url: "https://yt.sidestories.space/centers/all",
  };

  await axios(axiosGETConfig).then((allCenters) => {
    // eslint-disable-next-line array-callback-return
    allCenters.data.map((center) => {
      FXFCenters.push(center.centerCode);
    });
  });
  return FXFCenters;
}
