import React from "react";
import { Box, Grid, Typography, Paper, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

function WeatherGrid(props) {
  const { weatherArray, handleClose } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
    width: 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    my: "10px",
    flexGrow: 1
  }));

  return (
    <Box sx={style}>
      <Box sx={{ display: "flex", alignItems: "right", ml: 75 }}>
      <IconButton>
           <CloseIcon onClick={handleClose} />
      </IconButton>
     
      </Box>

      <Box sx={{ justifyContent: "center", mx: "auto", display: "flex" }}>
        <Typography variant="h2" sx={{ display: "flex" }}>
          Weather
        </Typography>
      </Box>
      <Grid container direction="column">
        {weatherArray.map((locale, key) => {
          console.log("locale in weather Map", locale);
          let forecast = locale.forecastObj;
          if (forecast.periodOne) {
            return (
              <div key={key}>
                <Item>
                  <Grid item>
                    <Typography variant="h5" sx={{ pb: "6px" }}>
                      {locale.city}, {locale.state}
                    </Typography>
                    <Grid container direction="row">
                      <Grid item xs={4}>
                        <Typography>
                          <strong>{forecast.periodOne.name}</strong>
                        </Typography>
                        <br />
                        <Typography>
                          {forecast.periodOne.shortForecast}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          <strong>{forecast.periodTwo.name}</strong>
                        </Typography>
                        <br />
                        <Typography>
                          {forecast.periodTwo.shortForecast}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          <strong>{forecast.periodThree.name}</strong>
                        </Typography>
                        <br />
                        <Typography>
                          {forecast.periodThree.shortForecast}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
                <br />
              </div>
            );
          } else return null;
        })}
      </Grid>
    </Box>
  );
}

export default WeatherGrid;
