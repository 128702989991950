import axios from "axios";

export async function searchWeather(locale) {
  let weatherArray = [];
  const axiosGETConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    mode: "cors",
    url: "https://api.weather.gov/points/" + locale.lat + "," + locale.lng,
  };
  try {
    await axios(axiosGETConfig).then(async (res) => {
      // console.log("your query returned the following number of bids: ", res.data.properties);

      const axiosGETforecast = {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        mode: "cors",
        url: res.data.properties.forecast,
      };

      await axios(axiosGETforecast).then((forecast) => {
        let forecastArray = forecast.data.properties.periods;
        weatherArray.push(
          forecastArray[0],
          forecastArray[1],
          forecastArray[2],
          forecastArray[3],
          forecastArray[4],
          forecastArray[5],
          forecastArray[6],
          forecastArray[7],
          forecastArray[8],
          forecastArray[9]
        );
      });
    });
    // console.log(weatherArray);
    return weatherArray;
  } catch (err) {
    console.log(err);
    try {
      await axios(axiosGETConfig).then(async (res) => {
        // console.log("your query returned the following number of bids: ", res.data.properties);

        const axiosGETforecast = {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          mode: "cors",
          url: res.data.properties.forecast,
        };

        await axios(axiosGETforecast).then((forecast) => {
          let forecastArray = forecast.data.properties.periods;
          weatherArray.push(
            forecastArray[0],
            forecastArray[1],
            forecastArray[2],
            forecastArray[3],
            forecastArray[4],
            forecastArray[5],
            forecastArray[6],
            forecastArray[7],
            forecastArray[8],
            forecastArray[9]
          );
        });
      });
      // console.log(weatherArray);
      return weatherArray;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
}

