import React, { useState } from "react";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,

  useJsApiLoader,
} from "@react-google-maps/api";
import { Box } from "@mui/material";

function TravelMap(props) {
  const { PUaddress, DOaddress, setTravelInfo, setDirectionsRes } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBs6JPnud56PGiauo305qCVrY5B3cqWd4M",
  });

  let directionOptions = {
    origin:
      "FedEx Freight " +
      PUaddress.street +
      "  " +
      PUaddress.city +
      " , " +
      PUaddress.state,
    destination:
      "FedEx Freight " +
      DOaddress.street +
      "  " +
      DOaddress.city +
      " , " +
      DOaddress.state,
    provideRouteAlternatives: false,
    travelMode: "DRIVING",
    drivingOptions: {
      departureTime: new Date(/* now, or future date */),
      trafficModel: "pessimistic",
    },
  };


  return isLoaded &&
    (PUaddress.street !== "Enter FXF Code" || PUaddress.street !== "") &&
    (DOaddress.street !== "Enter FXF Code" ||
      DOaddress.street !== "Enter FXF Code") ? (
    <Box>
      <DirectionsService
        options={directionOptions}
        callback={(res, status) => {
          if (status === "OK") {
            setDirectionsRes(res);
            let route = res.routes[0].legs[0];
            console.log("The Route from DirectionsService", res);

            setTravelInfo({
              distance: route.distance.text,
              travelTime: route.duration_in_traffic.text,
              startAddress: route.start_address,
              endAddress: route.end_address,
              startPoints: {
                lat: route.start_location.lat(),
                lng: route.start_location.lng(),
              },
              endPoints: {
                lat: route.end_location.lat(),
                lng: route.end_location.lng(),
              },
              steps: route.steps,
              path: res.routes[0].overview_path,
            });
          } else {
            console.log("status of the query", status);
          }
        }}
      />
    </Box>
  ) : (
    <></>
  );
}

export default React.memo(TravelMap);
