import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import CloseIcon from "@mui/icons-material/Close";

import { simpleRoute, getForecast } from "../apis/simplifyRoute";
// import { routeWeather, searchWeather } from "../apis/GETWeather";
// import { Grid } from "@mui/material";
import WeatherGrid from "./WeatherGrid";

function WeatherModal(props) {
  const { setOpen, open, travelInfo } = props;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [weatherArray, setWeatherArray] = useState([]);

  useEffect(() => {
    let start = Number(travelInfo.startPoints.lng);
    let end = Number(travelInfo.endPoints.lng);
    function retrieveWeather() {
      simpleRoute(
        travelInfo.path,
        Number(travelInfo.startPoints.lng),
        Number(travelInfo.endPoints.lng)
      ).then((routeArray) => {
        setWeatherArray(routeArray);
        console.log("unsorted route array", routeArray);
        setTimeout(() => {
          if (start > end) {
            console.log("Start > End");

            setWeatherArray(
              routeArray
                .sort((a, b) => {
                  return a.coordinates.lng - b.coordinates.lng;
                })
                .reverse()
            );
          } else {
            console.log("End > Start");
            setWeatherArray(
              routeArray.sort((a, b) => {
                return a.coordinates.lng - b.coordinates.lng;
              })
            );
          }
        }, 1000);
      });
    }
    retrieveWeather();
  }, [travelInfo]);

  useEffect(() => {
    console.log("UseEffect Weather Array", weatherArray);
  }, [weatherArray]);

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="outlined"
        sx={{ background: "white", color: "black", width: "215px" }}
      >
        Weather Information
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {weatherArray.length > 0 ? (
            <>
             
              <WeatherGrid weatherArray={weatherArray} handleClose={handleClose}/>
            </>
          ) : (
            <Typography variant="h4">loading</Typography>
          )}
        </>
      </Modal>
    </div>
  );
}

export default WeatherModal;
