import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";

function EntryCard(props) {
  const { fieldEntry, setField, address, centers } = props;


  return (
    <Card
      sx={{
        minWidth: 350,
        maxWidth: "6rem",
        mx: "auto",
        backgroundColor: "#dadada",
        boxShadow: 10,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Typography sx={{ color: "black" }} variant="h3">
          {props.title}
        </Typography>
        <br />

        <Autocomplete
          disablePortal
          id={props.title + "-entry"}
          options={centers}
          sx={{ backgroundColor: "white" }}
          renderInput={(params) => <TextField {...params} label="FXF Center" />}
          placeholder="3 Letter center Code"
          variant="outlined"
          value={fieldEntry}
          onChange={(e, newValue) => {
            // console.log("Saved", e);
            setField(newValue);
          }}
          onKeyDown={(e, newValue) => {
            // console.log("From Tab Press", e, newValue);
            if (e.code === "Tab" || e.code === "Enter") {
              setField(e.target.value.toUpperCase());
            }
          }}
        />
        <Box sx={{ py: ".5rem" }}></Box>
        {address.centerCode ? (
          <Typography variation="h4">
            <strong>{address.centerCode.toUpperCase()}</strong>
          </Typography>
        ) : null}
        <Typography>
          <strong> Address: </strong> {address.street}
        </Typography>
        {address.street2 && address.street2 !== "n/a" ? (
          <Typography>
            <strong>Address2: </strong>
            {address.street2}
          </Typography>
        ) : null}
        <Typography>
          <strong> City:</strong> {address.city} <br />
          <strong>State:</strong>
          {address.state} <br />
          <strong>Zip:</strong>
          {address.zip}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default EntryCard;
